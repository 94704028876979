@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 0 0% 3.9%;
    --inverted: 0 0% 3.9%;
    --inverted-foreground: 0 0% 100%;
    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;
    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;
    --primary: 0 0% 0%;
    --primary-foreground: 0 0% 100%;
    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;
    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;
    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;
    --destructive: 347 77% 38%;
    --destructive-foreground: 210 40% 98%;
    --neutral: 210 17% 95%;
    --neutral-foreground: 230 7% 16%;
    --interactive-foreground: 221, 83%, 53%;
    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --ring: 221.2 83.2% 53.3%;
    --radius: 0.25rem;
    --default-radius: 0rem;
  }

  .dark {
    --background: 0 0% 3.9%;
    --foreground: 220 9% 94%;
    --inverted: 220 9% 94%;
    --inverted-foreground: 0 0% 3.9%;
    --card: 222.2 84% 4.9%;
    --card-foreground: 210 40% 98%;
    --popover: 222.2 84% 4.9%;
    --popover-foreground: 210 40% 98%;
    --primary: 0 0% 100%;
    --primary-foreground: 0 0% 0%;
    --secondary: 217.2 32.6% 17.5%;
    --secondary-foreground: 210 40% 98%;
    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;
    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 210 40% 98%;
    --destructive: 347 77% 50%;
    --destructive-foreground: 210 40% 98%;
    --neutral: 230 7% 16%;
    --neutral-foreground: 210 17% 95%;
    --interactive-foreground: 221, 83%, 53%;
    --border: 217.2 32.6% 17.5%;
    --input: 217.2 32.6% 17.5%;
    --ring: 224.3 76.3% 48%;
  }
}

@layer base {
  * {
    @apply border-border;
  }

  @media (prefers-color-scheme: dark) {
    html {
      color-scheme: dark;
    }
  }

  @supports (font: -apple-system-body) and (-webkit-appearance: none) {
    img[loading="lazy"] {
      clip-path: inset(0.6px);
    }
  }

  a,
  input,
  button {
    @apply focus-visible:outline-2 focus-visible:ring-neutral-400 focus-visible:ring-offset-2 focus-visible:ring-offset-neutral-50 dark:focus-visible:ring-neutral-600 dark:focus-visible:ring-offset-neutral-900;
  }

  body {
    @apply bg-background text-foreground;
  }
}

@media (max-width: 400px) {
  .collection-mini-tile .square-tile,
  .collection-mini-tile .square-tile a {
    max-width: 130px;
  }

  .collection-mini-tile .all-tile {
    max-height: 130px;
  }

  .collection-mini-tile .product-price {
    @apply text-xs;
  }
}

/**
  * ----------------------------------------
  * swiper Overrides
  * ----------------------------------------
*/
@layer utilities {
  .swiper.custom-swiper.px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .swiper.custom-swiper.px-1\.5 {
    padding-left: 0.375rem;
    padding-right: 0.375rem;
  }
}

.swiper-button-prev.swiper-button-prev,
.swiper-button-next.swiper-button-next {
  color: hsl(var(--background));
  --swiper-navigation-size: 24px;
}

/**
  * ----------------------------------------
  * react-player Overrides
  * ----------------------------------------
*/
.react-player-video video {
  min-height: 100%;
  max-width: 100% !important;
  height: auto !important;
}

.star {
  clip-path: polygon(
    50% 0%,
    61% 35%,
    98% 35%,
    68% 57%,
    79% 91%,
    50% 70%,
    21% 91%,
    32% 57%,
    2% 35%,
    39% 35%
  );
}
